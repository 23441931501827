// Generated by Framer (353ff71)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["dfNOVzuz7", "TcFyqDWvn"];

const serializationHash = "framer-jMPaA"

const variantClassNames = {dfNOVzuz7: "framer-v-1cfsf1r", TcFyqDWvn: "framer-v-bzwued"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "dfNOVzuz7", "Variant 2": "TcFyqDWvn"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "dfNOVzuz7"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "dfNOVzuz7", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1cfsf1r", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"dfNOVzuz7"} ref={refBinding} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.08)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", background: "conic-gradient(from 0deg at 50% 50%, rgba(255, 255, 255, 0.48) 0deg, rgba(255, 255, 255, 0) 360deg)", borderBottomLeftRadius: 300, borderBottomRightRadius: 300, borderTopLeftRadius: 300, borderTopRightRadius: 300, ...style}} {...addPropertyOverrides({TcFyqDWvn: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1t6whyy"} data-border layoutDependency={layoutDependency} layoutId={"T8AKHyfC7"} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.16)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}} transformTemplate={transformTemplate1}/><motion.div className={"framer-1hlh61o"} data-border layoutDependency={layoutDependency} layoutId={"FLlbw_Blo"} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.16)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}} transformTemplate={transformTemplate1}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jMPaA.framer-ckd4xn, .framer-jMPaA .framer-ckd4xn { display: block; }", ".framer-jMPaA.framer-1cfsf1r { height: 600px; overflow: hidden; position: relative; width: 600px; will-change: var(--framer-will-change-override, transform); }", ".framer-jMPaA .framer-1t6whyy { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 440px); left: 50%; overflow: hidden; position: absolute; top: 50%; width: 440px; will-change: var(--framer-will-change-override, transform); }", ".framer-jMPaA .framer-1hlh61o { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 272px); left: 50%; overflow: hidden; position: absolute; top: 50%; width: 272px; will-change: var(--framer-will-change-override, transform); }", ".framer-jMPaA[data-border=\"true\"]::after, .framer-jMPaA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 600
 * @framerIntrinsicWidth 600
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"TcFyqDWvn":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRXWIz0qXw: React.ComponentType<Props> = withCSS(Component, css, "framer-jMPaA") as typeof Component;
export default FramerRXWIz0qXw;

FramerRXWIz0qXw.displayName = "Radar aniamtion";

FramerRXWIz0qXw.defaultProps = {height: 600, width: 600};

addPropertyControls(FramerRXWIz0qXw, {variant: {options: ["dfNOVzuz7", "TcFyqDWvn"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerRXWIz0qXw, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})